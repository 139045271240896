html * {
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  /* color: #fff !important; */
  /* color: #000 !important; */
  /* font-size: clamp(2rem, 4vw, 3rem); */
}

body {
  font-family: 'Ubuntu', sans-serif;

}


/* .h1_l {
  font-family: 'Krona One', sans-serif;
  font-size: 4rem;
  color: #16379F;
  font-weight: 400;
  border-top: 30px solid #5ff4ee;
  padding-top: 1rem;
  position: absolute;
  top: 50%;
  bottom: 0;
} */

.h1 {
  font-family: 'Krona One', sans-serif;
  font-size: 3rem;
  color: #2254fd;
  padding-top: 3.6rem;
  /* padding-right:1rem; */
  /* z-index: 2 !important; */
  /* margin-right: 3.6rem; */
}



.p11 {
  font-size: 1.3rem;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  padding-right: 2rem;

  color: rgb(0, 0, 0);
  z-index: 5;
  position: relative;


}

.p11::after {
  content: "";
  position: absolute;
  width: 40vw;
  height: 40vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #b3d9f7 0%, #ffffff00 100%);
  transform: translate(40%, -40%);
  top: -70%;
  right: -10%;
  opacity: 1;
  z-index: -5;
  -webkit-text-fill-color: transparent;
}





/*************************-text-************************/
@keyframes shine {
  to {
    background-position: -200% top;
  }
}

.text-gradient {
  background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shining {
  /* background: linear-gradient(135deg, #033fff 80%, #4a9ff5 40%, #5ff4ee 60%, #5ff4ee 5%); */
  background: linear-gradient(232.52deg, #033fff 60%, rgba(74, 159, 245, 0.8) 23.26%, rgba(95, 244, 238, 0.8) 43.44%, #033fff 71.7%);
  background-size: 200% auto;
  color: #fff;
  padding-bottom: 8px;
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5s linear infinite
}


.bg-gradient-primary-to-secondary {
  background: linear-gradient(45deg, #9f1ae2, #2937f0) !important;
}

.cus-btn {
  font-family: sans-serif;
  font-size: 18px;
  padding: 12px 32px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 50px;
}

.btn-gradient-border {
  color: rgb(var(--text-color));
  border: 2px double transparent;
  background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  -webkit-background-clip: padding-box, border-box;
  background-clip: padding-box, border-box;
  
}


.absolute-wrapper {
  position: absolute;
  left: 4.14%;
  right: 43.19%;
  top: 13.89%;
  bottom: 17.94%;
}

.Saly-18 {
  position: absolute;
  /* width: 822px; */
  /* height: 822px; */
  left: 0px;
  top: 0px;

  /* background: url(Saly-18.png); */
  /* transform: rotate(90deg); */

}


.don {
  position: absolute;
  left: 12.87%;

  top: 18.03%;

  width: 100%;

}


.header-text {
  /* position: absolute;
  right: 3rem;
  top: 15rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000; */
  font-family: 'Krona One', sans-serif;
  font-weight: 700;
}

.text-yellow {
  color: #ffc107;
}

.p-text {
  position: absolute;
  /* width: 108px;
height: 21px; */
  right: 3.5rem;
  top: 13rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #000000;
}



.p-after-text {
  position: absolute;
  top: 25rem;
  right: 3rem;
  display: flex;
  align-items: center;
  text-align: right;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  color: #666666;

}

.header-button {
  position: absolute;
  width: 218px;
  height: 69px;
  right: 3rem;
  /* left: 650px; */
  top: 30rem;
  align-items: center;
  background: linear-gradient(148.33deg, #5741B2 19.08%, #523B7E 97.7%);
  border-radius: 50px;
}

/*************************-nav-************************/
.navbar {
  padding-top: .5rem;
  padding-bottom: .5rem;
  /* background-color: #fff; */
}


.nav-link {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: end;
  color: #E1FFF8;
}

.nav-link:hover {
  color: #E1FFF8 !important;
  /* opacity: 0.5 !important; */
}

.nav-link-active {
  color: #E1FFF8 !important;

}

.nav-link-active:hover {
  color: #E1FFF8 !important;
  opacity: 0.9 !important;

}

/* .navbar{
  height: 190px;
} */

#pl-3 {
  padding-left: 1.5rem !important;
}

.nav-bg-blur {
  -webkit-backdrop-filter: blur(3px);
  -moz-backdrop-filter: blur(3px) saturate(180%);
  backdrop-filter: blur(3px) saturate(110%);

  background-color: transparent;
}

/* .collapse.show {
  display: block !important;
} */


.navbar-active {
  background-color: black;
  /* height: 100px; */

}

.hamburger-active {
  background: white !important;
}

.logo-active {
  
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@media (min-width: 768px) {
  .hamburger-react {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hamburger-react {
    display: none;
  }
}

/* .navbar-nav > li{
  padding-left:30px;
  padding-right:30px;
} */

/*************************-about-************************/

.about-container {
  height: 100vh;
  /* height: 100%; */
  /* width: 100vw; */
  /* background: linear-gradient(270.42deg, rgba(95, 244, 238, 0.52) 0.5%, #93C8FF 32.99%, #4fb6ff 99.52%); */
  background: linear-gradient(78.88deg, rgba(95, 244, 238, 0.52) 46.47%, rgba(95, 190, 244, 0.93) 111.98%);
  overflow: hidden;
  position: relative;
  z-index: -2;
  display: block;
}

/* .about-container:hover {
  background: linear-gradient(80.61deg, #d3ff9e 8.29%, rgba(136, 168, 216, 0) 90.54%);
} */

.pad {
  position: relative;
  bottom: 160%;
  /* left: 10%; */
  /* right: 20%; */
  /* justify-content: center; */
  display: flex;
  /* align-items: center; */
  /* color: #999999; */
  background: transparent;
  /* height: 60vh; */
  /* width: 40vw; */
  z-index: 3;
  width: 1100px;

}

.pad1 {
  position: absolute;
  bottom: 4vh;
  left: 9vw;
  display: flex;
  align-items: center;
  /* color: #999999; */
  background: transparent;
  height: 75vh;
  width: 40vw;
  /* width: 40vw; */
  z-index: 3;

}

.dony {
  position: absolute;
  bottom: 0vh;
  left: 15%;
  /* right: 25vw; */
  display: flex;
  align-items: center;
  /* color: #999999; */
  background: transparent;
  height: 60vh;
  /* transform: matrix(-1, -0.01, -0.01, 1, 0, 0); */
  z-index: 3;

}

/* #circle1 {
  width: 10.75vw;
  height: 10.75vw;
  background:  linear-gradient(236.21deg, rgba(241, 252, 255, 0.8) 15.78%, rgba(244, 245, 247, 0.632) 68.98%);
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  left: 43.58vw;
 
  top: 10.42vh;
  z-index: -1;
} */

#circle2 {
  width: 6.67vw;
  height: 6.67vw;
  background: linear-gradient(63.47deg, #EEFDFF 13.23%, #B1E3EA 93.14%);
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  left: 34vw;
  top: 18.67vh;
  z-index: -1;
}

/* #circle3 {
  width: 25.58vw;
  height: 25.58vw;
  background: linear-gradient(63.47deg, rgba(125, 146, 190, 0.6) 13.23%, rgba(197, 203, 221, 0.6) 93.14%);
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  left: 42.25vw;
  top: 16.17vh;
  z-index: -1;
} */

/* #circle4 {
  width: 19.75vw;
  height: 19.75vw;
  background: linear-gradient(63.47deg, rgba(155, 188, 255, 0.8) 13.23%, rgba(197, 203, 221, 0.8) 93.14%);
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  left: 29.42vw;
  top: 48.25vh;
  z-index: -1;
} */

/* #circle5 {
  width: 6.67vw;
  height: 6.67vw;
  background: linear-gradient(3.68deg, #B0C9FF 2.15%, #6896F4 59.57%, #AA95CE 98.54%);
  transform: rotate(-127.91deg);
  box-shadow: -3px -15px 4px 1px rgba(22, 56, 165, 0.1);
  border-radius: 50%;
  position: absolute;
  left: 29.42vw;
  bottom: -4.25vh;
  z-index: -1;
} */

#circle6 {
  width: 8.75vw;
  height: 8.75vw;
  background: linear-gradient(63.47deg, rgba(128, 161, 232, 0.8) 13.23%, rgba(142, 163, 205, 0.8) 44.03%, rgba(197, 203, 221, 0.8) 93.14%);
  border-radius: 50%;
  position: absolute;
  left: 60.42vw;
  top: 70.25vh;
  z-index: -1;
}

#circle7 {
  width: 5vw;
  height: 5vw;
  background: linear-gradient(63.47deg, #EEFDFF 13.23%, #B1E3EA 93.14%);
  border-radius: 50%;
  position: absolute;
  right: 5.58vw;
  top: 48.25vh;
  border-radius: 40px;
  overflow: hidden;
  z-index: -1;
}

.circle8 {
  width: 525px;
  height: 525px;
  background: linear-gradient(348.61deg, rgba(95, 235, 244, 0.63) 31.96%, rgba(255, 255, 255, 0.61) 58.56%);
  ;
  border-radius: 50%;
  opacity: .8;
  overflow: hidden;
  position: absolute;
  left: -20px;
  bottom: -240px;
  border-radius: 50%;
  background-blend-mode: hard-light;
  z-index: 1;

}

.glass1 {
  background-image: url(../pictures/glass.png);
  background-repeat: no-repeat;
  background-size: cover;
  top: 13vh;
  /* max-width: 90%; */
  /* max-height: 180%; */
  z-index: 1;
  width: 96vw;
  height: 79.6vh;
  position: relative;
}




.bg .glass1::before {
  width: 96vw;
  height: 70.3vh;
  background: rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  border: double 0.05rem transparent;
  -moz-backdrop-filter: blur(.6rem);
  -webkit-backdrop-filter: blur(.6rem);
  backdrop-filter: blur(.6rem);
  top: 2px;
  left: 7px;
  position: absolute;
  content: '';
  border-radius: 40px;
  z-index: 0;

}


/* .bg .glass1::after{
  width: 97.25vw;
  height: 86.46vh;
  content: '';
  position: absolute;
  inset: 5px;
  border-radius: 40px;
  box-sizing: border-box;
  border:10px, solid;
  border-image: linear-gradient(100deg, rgba(3, 63, 255, 1), rgba(181, 128, 255, 1), rgba(245, 165, 197, 1), rgba(168, 255, 62, 0.48), rgba(168, 255, 62, 0))1;
} */

.p-before-text {
  font-family: 'Krona One';
  position: absolute;
  font-weight: 100;
  top: 20vh;
  right: 10vw;
  display: flex;
  align-items: center;
  color: #456ffe;
  /* color: #999999; */
  /* background: #013bfa63 ; */
  font-size: 2vh;
  z-index: 3;

}

.p-before-text3 {
  font-family: 'Krona One';
  position: absolute;
  font-weight: 100;
  top: 22vh;
  right: 8vw;
  display: flex;

  text-align: right;
  align-items: center;
  color: #456ffe;
  /* color: #999999; */
  /* background: #013bfa63 ; */
  font-size: 1.5vh;
  z-index: 3;

}

.p-before-text2 {
  font-family: 'Krona One';
  position: absolute;
  font-weight: 100;
  left: 50vw;
  top: 25vh;
  right: 8vw;
  display: flex;
  align-items: center;
  text-align: right;
  color: #456ffe;
  /* color: #999999; */
  /* background: #013bfa63 ; */
  font-size: 3vh;
  z-index: 3;

}

.main-heading {
  position: absolute;
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  display: flex;
  align-items: center;
  text-align: right;

  /* color: #16379F; */
  color: #2254fd;
  right: 10vw;
  bottom: 14vh;
  left: 40vw;
  z-index: 2;
}

.main-heading1 {
  position: absolute;
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  display: flex;
  align-items: center;
  text-align: right;

  /* color: #16379F; */
  color: #2254fd;
  right: 2px;
  bottom: 10vh;
  left: 58vw;
  z-index: 3;
}


.about-container1 {
  background: linear-gradient(270.42deg, rgba(187, 245, 243, 0.52) 0.5%, #93C8FF 32.99%, #90a8f6 99.52%);
  overflow: hidden;
  position: relative;
}

.p-before-text1 {
  font-family: 'Krona One';
  /* position: absolute; */
  /* top: 30vh;
  right: 2vw; */
  display: flex;
  align-items: center;
  /* color: #999999; */
  background: #013bfa63;
  font-size: 1.2vh;
}

.main-heading1 {
  /* position: absolute; */
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #16379F;



}

/*************************-what-************************/

.what-container {
  /* height: 100vh; */
  /* height: 100%; */
  /* width: 100vw; */
  background: white;
  overflow: hidden;
  /* position: relative; */
  z-index: 0;
}

/* .sphere{
  width:50vw;
  height:50vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #FFEBA6 0%, #FFFFFF 100%) ;
  z-index:-1;

} */



/* .what-container .bg1::after{
  width:55vw;
  height:55vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #FFEBA6 0%, #FFFFFF 100%) ;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  position: absolute;
  content: '';
  z-index: -2;

} */


.what-container .house {
  width: 50%;
  opacity: 1;
  right: 10%;
  position: absolute;
  z-index: 2;
  top: -30%;

}


.what-container .house1 {
  width: 30%;
  opacity: 1;
  /* top: -10%; */
  bottom: 3rem;
  right: 5rem;
  position: absolute;
  z-index: 0;
  /* padding: 3rem; */

  /* padding-right: 2rem; */
}


.what-container .house2 {
  width: 25%;
  opacity: 1;
  /* bottom: 10vh; */
  /* right: 1vw; */
  position: absolute;
  z-index: 0;
}

.what-container .house3 {
  width: 25%;
  opacity: 1;
  bottom: -1vh;
  right: 5rem;
  position: absolute;
  z-index: 5;

}

.what-container .house4 {
  width: 20%;
  opacity: 1;
  top: -220%;
  right: 5rem;
  position: absolute;
  z-index: 5;
  padding-bottom: 3rem;
}



.what-container .text {
  /* position: absolute; */
  z-index: 1;
}

.what-container .text1 {
  /* position: absolute; */
  z-index: 1;
  color: #fa9a85;
}

.wh_1 {
  font-family: 'Krona One', sans-serif;
  font-size: 3rem;
  color: #2254fd;
  padding-top: 3.6rem;
  position: relative;
  z-index: 5;

}

.wh_1::before {
  content: "";
  position: absolute;
  top: 1.2rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 5;
  border-radius: 50px;
}


/* .what-container .wh_1::after {
  content: url(../pictures/acc_0148.png);
  position: absolute;
  width:10vw;
  height:10vw;
  transform: scale(.6);
  top:-200%;
  right:10%;
  opacity: 1;
  z-index: -1;
} */

.wh_2 {
  font-size: 3rem;
  color: #2254fd;
  z-index: 1;
  position: relative;
  padding-bottom: 2rem;
  padding-left: 1rem;

}

.what-container .wh_2::before {
  content: "";
  position: absolute;
  top: 5rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 1;
  border-radius: 50px;
}

.what-container .wh_2::after {
  padding-top: 4rem;
  content: "";
  position: absolute;
  width: 55vw;
  height: 55vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #FFEBA6 0%, #ffffff00 100%);
  top: 80%;
  left: 20%;
  transform: translate(-60%, -50%);
  opacity: 1;
  z-index: -3;

}

.wh_22 {
  font-size: 3.5rem;
  color: #2254fd;
  z-index: 1;
  position: relative;
  padding-bottom: 2rem;
  padding-left: 1rem;

}


.what-container .wh_22::before {
  content: "";
  position: absolute;
  top: 1.2rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 1;
  border-radius: 50px;
}

.padd {}

.what-container .wh_22::after {
  padding-top: 4rem;
  content: "";
  position: absolute;
  width: 55vw;
  height: 55vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #FFEBA6 0%, #FFFFFF 100%);
  top: 70%;
  left: 10%;

  transform: translate(-60%, -50%);
  opacity: 1;
  z-index: -6;

}

.wh_222 {
  font-size: 3.5rem;
  color: #2254fd;
  z-index: 1;
  position: relative;
  padding-bottom: 5rem;
  padding-left: 14rem;
}

.what-container .wh_222::before {
  content: "";
  position: absolute;
  top: 1.2rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 1;
  border-radius: 50px;
}

.what-container .wh_222::after {
  padding-top: 4rem;
  content: "";
  position: absolute;
  width: 55vw;
  height: 55vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #FFEBA6 0%, #FFFFFF 100%);
  top: 80%;
  left: 20%;
  transform: translate(-60%, -50%);
  opacity: 1;
  z-index: -6;

}

.what-container .wh_2::before {
  content: "";
  position: absolute;
  top: 1.2rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 1;
  border-radius: 50px;
}

.what-container .wh_2::after {
  padding-top: 4rem;
  content: "";
  position: absolute;
  width: 55vw;
  height: 55vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #FFEBA6 0%, #FFFFFF 100%);
  top: 80%;
  left: 20%;
  transform: translate(-60%, -50%);
  opacity: 1;
  z-index: -6;

}

.acce {
  z-index: 5;
  position: absolute;
  top: -8rem;
  right: -9rem;
  padding-top: 3.6rem;
}


/* .what-container .before{
  position: absolute;
} */

/*************************-why-************************/

/* .why-container{
  overflow: hidden;
  height: 100vh;

  width: 100vw;

  position: relative;
  background-image: url(../pictures/looking_outside_v6.png);
  background-size: 100px 100px;
  background-repeat: no-repeat;
  padding: 4rem 0;
   
  background-size:cover;
  background-position: center;


} */

.why-container {
  /* height: 100vh; */
  /* height: 100%; */
  /* width: 100vw; */

  background: linear-gradient(78.88deg, rgba(248, 204, 195, 0.93) 46.47%, rgba(248, 204, 195, 0.673) 111.98%);
  overflow: hidden;
  position: relative;
  z-index: -1;
  display: block;
}

.p12 {
  font-size: 1rem;
  font-family: 'Krona One', sans-serif;
  font-style: normal;
  padding-right: 3rem;
  padding-left: 2rem;
  width: 98%;

  color: white;
  z-index: 5;
  position: relative;

}

.p12::before {
  content: "";
  width: 10px;
  height: 10px;
  /* top: -5rem; */
  left: 1rem;
  right: 1rem;
  /* height: 5px; */
  /* width: 7%; */
  background: #fa9a85;
  opacity: 1;
  z-index: 5;
  border-radius: 50%;
  position: absolute;

}


/* .p12::after{
  content: "*";
 
  top: 7rem;
  left: 2rem;
  

  width: 80%;
  
  opacity: 1;
  z-index: 5;
  border-radius: 50%;
  position: absolute;
  font-size:.6rem;
  padding-right: 3rem;


} */

.why .text {
  position: relative;
  z-index: 1;

}



.wh_3 {
  font-family: 'Krona One', sans-serif;
  font-size: 3rem;
  color: #2254fd;
  padding-top: 3.6rem;
  position: relative;
  z-index: 5;
  width: 82%;

}

.wh_3::before {
  content: "";
  position: absolute;
  top: 1.5rem;
  /* right:50%; */
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 5;
  border-radius: 50px;
  display: inline-block;
}

.stanford {


  width: 100%;
  height: auto;
  overflow: visible;


}




.spinner {
  position: relative;
  overflow: hidden;
  padding: 5px 0;
  /* padding-left: -10px; */
  background: #8cc0fc;
  width: 100vw;
  --tw: 16em;
  --ad: 15s;
  --tw1: 40rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 5;
}

@media screen and (min-width: 2100px) {
  .collection-marquee {
    --tw: 24.5rem
  }
}

.spinner .inner {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-none), 0, 0);
  transform: translate3d(var(--move-initial), 0, 0);
  -webkit-animation: marquee 15s linear infinite;
  animation: marquee 15s linear infinite;
  /* background: #033effd1; */
}

.spinner span {
  font-size: 1.5rem;
  color: #3983f2;
  width: var(--tw);
  text-shadow: var(--tw) 0 currentColor, calc(var(--tw)*2) 0 currentColor, calc(var(--tw)*3) 0 currentColor, calc(var(--tw)*4) 0 currentColor, calc(var(--tw)*5) 0 currentColor, calc(var(--tw)*6) 0 currentColor, calc(var(--tw)*7) 0 currentColor, calc(var(--tw)*8) 0 currentColor, calc(var(--tw)*9) 0 currentColor, calc(var(--tw)*10) 0 currentColor, calc(var(--tw)*11) 0 currentColor, calc(var(--tw)*12) 0 currentColor;
  /* will-change: transform; */
  -webkit-animation: marquee var(--ad) linear infinite;
  animation: marquee var(--ad) linear infinite
}

.spinner .span1 {
  /* font-family: Ubuntu,sans-serif; */
  font-size: 2rem;
  text-transform: uppercase;
  /* font-weight: 800; */
  /* padding: 0 .5rem; */
  color: #5FF4EE;
  /* white-space: nowrap; */
  width: 100vw;
  text-shadow: var(--tw1) 0 currentColor, calc(var(--tw1)*2) 0 currentColor, calc(var(--tw1)*3) 0 currentColor, calc(var(--tw1)*4) 0 currentColor, calc(var(--tw1)*5) 0 currentColor, calc(var(--tw1)*6) 0 currentColor, calc(var(--tw1)*7) 0 currentColor, calc(var(--tw1)*8) 0 currentColor, calc(var(--tw1)*9) 0 currentColor, calc(var(--tw1)*10) 0 currentColor;
  will-change: transform;
  -webkit-animation: marquee var(--ad) linear infinite;
  animation: marquee var(--ad) linear infinite
}

@media screen and (min-width: 2100px) {
  .spinner span {
    font-size: 2rem;
  }
}



@-webkit-keyframes marquee {
  0% {
    transform: translateX(0)
  }

  to {
    transform: translateX(-100%)
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0)
  }

  to {
    transform: translateX(-100%)
  }
}



/*************************-roadmap_line-************************/

.line-container {
  /* height: 100vh;
  width: 100vw; */
  /* background: linear-gradient(270.42deg, rgba(95, 244, 238, 0.52) 0.5%, #93C8FF 32.99%, #4F79FF 99.52%); */
  /* overflow: hidden; */
  position: relative;
  /* background: rgba(128, 0, 128, 0.238); */
  /* z-index: -4; */
  overflow: hidden;



}



.line-container .container1 {
  height: 50vh;
}


.bg3::before {
  width: 90.33vw;
  height: 74.92vh;
  background: linear-gradient(180.31deg, rgba(255, 255, 255, 0.5) 5.63%, rgba(255, 239, 241, 0.5) 38.13%, rgba(74, 159, 245, 0.4) 81.47%, rgba(84, 227, 70, 0.3) 102.37%);
  filter: blur(30px);
  border-radius: 39px;
  position: absolute;
  bottom: 15vh;
  content: '';
  left: -18vw;
  z-index: -1;
}


.wh_4 {
  font-family: 'Krona One', sans-serif;
  padding-top: 3.6rem;
  font-size: 2rem;
  color: #16379F;
  /* padding-left: 3vw; */
  /* position: absolute; */
  /* bottom: 3vh; */
}

.line-container .wh_4::before {
  content: "";
  position: absolute;
  top: 2rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: 1.17rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 5;
}

/*************************-team-************************/

.team {
  /* height: 100vh;
  width: 100vw; */
  /* background: linear-gradient(270.42deg, rgba(95, 244, 238, 0.52) 0.5%, #93C8FF 32.99%, #4F79FF 99.52%); */
  /* overflow: hidden; */
  position: relative;
  background:#f7f7f796;
  /* z-index: -4;
   */
   /* background: linear-gradient(0.31deg, rgba(255, 255, 255, 0.5) 5.63%, rgba(255, 239, 241, 0.5) 38.13%, rgba(74, 159, 245, 0.4) 81.47%, rgba(84, 227, 70, 0.3) 102.37%); */
}

/* .team::before {
  height: 60vw; 
  width: 120vw;
  background: linear-gradient(70.1deg, rgba(194, 242, 231, 0.2) 13.09%, rgba(225, 255, 248, 0.2) 85.73%);
  border-radius: 50%;
  position: absolute;
  bottom: -10vh;
  content: '';
  left:-10vw;
  z-index: -1;
  filter: blur(2px);
  margin-left: auto;
  margin-right: auto;
  
} */


.wh_5 {
  font-family: 'Krona One', sans-serif;
  /* padding-top: 3rem; */
  font-size: 2.2rem;
  color: #033FFF;
  /* position: absolute; */
  /* bottom: 3vh; */
  position: relative;
}

.wh_5::before {
  content: "";
  position: absolute;
  top: 1.2rem;
  /* height: 5px; */
  /* width: 7%; */
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 5;
  border-radius: 50px;
}



.wh_7 {
  font-family: 'Krona One', sans-serif;
  /* padding-top: 3rem; */
  font-size: 2.2rem;
  color: #033FFF;
  text-align: center;
  /* padding-left: 3vw; */
  /* position: absolute; */
  /* bottom: 3vh; */
  position: relative;
}

/* .wh_7::before {
  content: "";
  position: absolute;
  top: -1.2rem;
  width: 4.17rem;
  height: .8rem;
  background: #5FF4EE;
  opacity: 1;
  z-index: 5;
  border-radius: 50px;
} */


.wh_8 {
  font-family: 'Krona One', sans-serif;
  /* padding-top: 3rem; */
  font-size: 2.2rem;
  color: #033FFF;
  /* text-align: center; */
  /* padding-left: 3vw; */
  /* position: absolute; */
  /* bottom: 3vh; */
  position: relative;
}

.th1 {
  font-size: 1.5rem;
  color: #FF3796;
  font-family: 'Krona One', sans-serif;

}

.circle10 {
  background: linear-gradient(180deg, #5FF4EE 0%, rgba(95, 244, 238, 0) 100%);
  border-radius: 50%;
  box-sizing: border-box;
  /* display: block; */
  width: 30vw;
  height: 30vw;
  text-align: center;
  overflow: visible;

}

.circle11 {
  background: linear-gradient(180deg, #A7FF3E 0%, rgba(168, 255, 62, 0) 100%);
  border-radius: 50%;
  box-sizing: border-box;
  /* display: block; */
  width: 30vw;
  height: 30vw;
  text-align: center;
  overflow: visible;

}

.circle12 {
  background: linear-gradient(180deg, #F7FD04 0%, rgba(168, 255, 62, 0) 100%);
  border-radius: 50%;
  box-sizing: border-box;
  /* display: block; */
  width: 30vw;
  height: 30vw;
  text-align: center;
  overflow: visible;

}

.circle13 {
  background: linear-gradient(180deg, #FFB9C5 0%, rgba(255, 185, 196, 0) 100%);
  border-radius: 50%;
  box-sizing: border-box;
  /* display: block; */
  width: 30vw;
  height: 30vw;
  text-align: center;
  overflow: visible;

}

.founder {
  position: absolute;
  bottom: 3%;
  text-align: center;
  align-self: center;

  left: -10%;
  width: 120%;
  height: 120%;
  overflow: visible;

}

.f_title {
  position: absolute;
  bottom: -120%;
  text-align: center;
  align-self: center;
  font-size: 1.3rem;

  left: -6%;
  /* width: 120%;
  height: 120%;
   */
  color: #16379F;

}
 
.f_title .f_title1 {
  position: absolute;
  bottom: -40%;
  text-align: center;
  align-self: center;
  font-size: 1rem;

  left: -10%;
  width: 120%;
  height: 120%;

  color: #C299FC;

}



/*************************-Intro-************************/

.bg-gradient-primary-to-secondary-intro {
  background: linear-gradient(45deg, #f8b925 8.29%, #fcc0d8) !important;
}

.intro-up {
  background: linear-gradient(45deg, #F5A5C5, #F9B208, #c3f804) !important;
}


.intro-up h1 {
  color: #fff !important;
}

.intro-left {
  background-color: #F5A5C5;
}

.intro-up button {
  line-height: 1.25rem;
  width: fit-content;
  width: -moz-fit-content;
  background-color: rgb(153, 69, 255);
  border-radius: 1.5rem;
  padding: 0.625rem 1.3125rem;
  color: white;
  /* transition: box-shadow .25s ease,transform .25s ease; */
}

.lift {
  transition: box-shadow .25s ease, transform .25s ease
}

/*************************-Coming-************************/



/*************************-faq-************************/
.glow0 {
  width: 18rem;
  height: 25rem;
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all .3s;

}

.glow1 {
  width: 18rem;
  height: 25rem;
  border-radius: 20px;
  background-color: #f0fff8;
  transition: all .2s;

}

.glow1:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
  border-radius: 20px;
}

.glow0:hover {
  transform: scale(0.98);
  border-radius: 20px;
}


/*************************-logo-************************/






/*************************-footer-************************/
.footer-container {
  height: 100%;
  width: 100%;
  background: #033FFF;
  color: #FFFFFF;
  overflow: hidden;

}

ul {
  padding-left: 0;
}

.footer-container li {
  display: flex;
  margin-bottom: 10px;
  /* text-align: left; */
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none !important;
}

.footer-container a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none !important;

}

.footer-container .p {
  font-size: 15rem !important;

}

.footer-container .img {
  /* display: flex; */
  width: 15%;
  height: 15%;
}

.footer-container .p1 {
  font-size: 1rem !important;
  color: rgba(255, 255, 255, 0.5) !important;

}


/* .footer-container :hover {
  background: linear-gradient(280.61deg, #d3ff9e 8.29%, rgba(136, 168, 216, 0) 90.54%);
  background: rgba(0,0,0, 0.9);
} */

.footer-container a:hover {
  color: #A8FF3E !important;
}


.footer-container hr {
  border-top-width: 1px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  color: #fff;
}






table {
  border-collapse: collapse;
  border-spacing: 0
}

table th {
  text-align: left
}

*,
*:before,
*:after,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}




.pill-button {
  display: inline-flex;
  position: relative;
  padding: 0 1.75rem;
  height: 2.75rem;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center
}

.pill-button.is-big {
  padding: 0 2.5rem;
  height: 3.5rem
}


.pill-button .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.pill-button .background .left {
  position: absolute;
  top: 0;
  left: -15px;
  right: 50%;
  height: 100%;
  overflow: hidden
}

.pill-button .background .left .plain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  background: rgba(255, 255, 255, .047);
  transform: translate(12px);
  transition: transform .2s;
  will-change: transform
}

.pill-button .background .right {
  position: absolute;
  top: 0;
  right: -15px;
  left: 50%;
  height: 100%;
  overflow: hidden
}

.pill-button .background .right .plain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  background: rgba(255, 255, 255, .047);
  transform: translate(-12px);
  transition: transform .2s;
  will-change: transform
}

.pill-button .label {
  display: flex;
  align-items: center;
  position: relative
}

.experience {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh
}

.sections {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  pointer-events: none
}

html.is-vertical.is-focused .sections {
  pointer-events: auto
}

.sections.is-scrollable {
  overflow-y: auto
}

.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none
}

.section.is-crew {
  --section-color: #a48bff
}

.section.is-privatekey {
  --section-color: #85a5ff
}

.section.is-onscout {
  --section-color: #fff
}

.section.is-isonline {
  --section-color: #f2acf2
}

.section.is-ideasby {
  --section-color: #f9d77f
}

.section.is-takemeto {
  --section-color: #fff
}

.section *::selection {
  background: var(--section-color)
}

.section *::-moz-selection {
  background: var(--section-color)
}

.section .reveal {
  transition: opacity .3s, transform .3s
}

html.is-vertical .section.is-active,
.section.is-active .content * {
  pointer-events: auto
}

.section.is-active .reveal {
  opacity: 1;
  transform: translate(0);
  transition-timing-function: ease-out, ease-out
}

.section.is-active .reveal.delay-in-0 {
  transition-delay: .3s
}

.section.is-active .reveal.delay-in-1 {
  transition-delay: .35s
}

.section.is-active .reveal.delay-in-2 {
  transition-delay: .4s
}

.section.is-active .reveal.delay-in-3 {
  transition-delay: .45s
}

.section.is-active .reveal.delay-in-4 {
  transition-delay: .5s
}

.section.is-active .reveal.delay-in-5 {
  transition-delay: .55s
}

.section.is-active .reveal.delay-in-6 {
  transition-delay: .6s
}

.section.is-active .reveal.delay-in-7 {
  transition-delay: .65s
}

.section.is-active .reveal.delay-in-8 {
  transition-delay: .7s
}

.section.is-active .reveal.delay-in-9 {
  transition-delay: .75s
}

.section.is-active .reveal.delay-in-10 {
  transition-delay: .8s
}

.section.is-active .focus-point {
  transform: scale(0);
  transition-delay: 0s
}

.section:not(.is-active) .reveal {
  opacity: 0;
  transform: translate(60px);
  transition-timing-function: ease-in, ease-in
}

.section:not(.is-active) .reveal.delay-out-0 {
  transition-delay: 0s
}

.section:not(.is-active) .reveal.delay-out-1 {
  transition-delay: .05s
}

.section:not(.is-active) .reveal.delay-out-2 {
  transition-delay: .1s
}

.section:not(.is-active) .reveal.delay-out-3 {
  transition-delay: .15s
}

.section:not(.is-active) .reveal.delay-out-4 {
  transition-delay: .2s
}

.section:not(.is-active) .reveal.delay-out-5 {
  transition-delay: .25s
}

.section:not(.is-active) .reveal.delay-out-6 {
  transition-delay: .3s
}

.section:not(.is-active) .reveal.delay-out-7 {
  transition-delay: .35s
}

.section:not(.is-active) .reveal.delay-out-8 {
  transition-delay: .4s
}

.section:not(.is-active) .reveal.delay-out-9 {
  transition-delay: .45s
}

.section:not(.is-active) .reveal.delay-out-10 {
  transition-delay: .5s
}

.section.is-content {
  text-align: left
}

.section.is-content .content {
  display: flex;
  min-height: 100vh;
  margin-right: 5%;
  margin-left: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center
}

html.is-vertical .section.is-content .content {
  display: block;
  margin-right: 0;
  margin-left: 0;
  padding-top: 60vh;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 14vh
}

.section.is-content .intro {
  margin-bottom: 60px
}


.section.is-content .intro .title {
  width: 80%;
  margin-bottom: .75em;
  font-family: "Sequel 100 Wide";
  font-size: 2em;
  line-height: 1.25em
}



.section.is-content .intro .description {
  width: 90%;
  line-height: 1.6em
}



.section.is-content .features {
  display: flex;
  width: 100%
}

.section.is-content .features .feature {
  width: calc(25% - 15px);
  margin-right: 15px
}

.section.is-content .features .feature .icon-container {
  position: relative;
  margin-bottom: 10px;
  background: #1a1a1a;
  border-radius: 16px
}

.section.is-content .features .feature .icon-container .ratio {
  padding-top: 100%
}

.section.is-content .features .feature .icon-container .icon-inner {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center
}



.section.is-content .features .feature .label {
  line-height: 1.4em;
  font-size: .85em;
  color: #fffb
}

.section.is-content .main-link {
  margin-top: 9vmin;
  letter-spacing: 1px;
  font-weight: 700;
  color: #000
}



.section.is-content .main-link .background .left .plain,
.section.is-content .main-link .background .right .plain {
  background: var(--section-color)
}

.section .focus-point {
  position: absolute;
  top: 50%;
  left: -150px;
  width: 150px;
  height: 150px;
  margin-top: -50px;
  margin-left: -50px;
  cursor: pointer;
  pointer-events: auto;
  transform: scale(1);
  transition: transform .3s cubic-bezier(.5, 0, .5, 1);
  transition-delay: .3s
}



.section .focus-point .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--section-color);
  transform: scale(.53);
  transition: transform .3s cubic-bezier(.6, -.45, .75, .9);
  transition-delay: 0s;
  will-change: transform
}

.section .focus-point .plus-icon {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  opacity: 1;
  transition: opacity .3s;
  transition-delay: .2s;
  will-change: opacity
}

.section .focus-point .plus-icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
  background: #000
}

.section .focus-point .plus-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  width: 100%;
  height: 4px;
  background: #000
}

.section .focus-point .label {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  color: #000;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .3s;
  transition-delay: 0s;
  will-change: opacity
}

.scroll-down-container {
  display: flex;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 6%;
  pointer-events: none;
  align-items: center;
  justify-content: center
}

.scroll-down {
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s 0s ease-in, transform .6s 0s ease-in;
  will-change: opacity, transform
}

.scroll-down.is-visible {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(0);
  transition: opacity .6s ease-out, transform .6s ease-out
}

.scroll-down .icon {
  position: relative;
  margin-right: 1.25rem
}

.scroll-down .icon .circle {
  position: absolute;
  top: -.5rem;
  left: -.5rem;
  width: 1rem;
  height: 1rem;
  border: 2px solid #fff;
  border-radius: .5rem;
  animation: scroll-down-icon-circle 4s infinite;
  transform-origin: 0% 0%
}

@-moz-keyframes scroll-down-icon-circle {
  0% {
    transform: translateY(0);
    height: 1rem
  }

  5% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  10% {
    transform: translateY(-.5rem);
    height: 1rem
  }

  15% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  20% {
    transform: translateY(0);
    height: 1rem
  }

  50% {
    transform: translateY(0);
    height: 1rem
  }

  55% {
    transform: translateY(0);
    height: 1.5rem
  }

  60% {
    transform: translateY(.5rem);
    height: 1rem
  }

  65% {
    transform: translateY(0);
    height: 1.5rem
  }

  70% {
    transform: translateY(0);
    height: 1rem
  }

  to {
    transform: translateY(0);
    height: 1rem
  }
}

@-webkit-keyframes scroll-down-icon-circle {
  0% {
    transform: translateY(0);
    height: 1rem
  }

  5% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  10% {
    transform: translateY(-.5rem);
    height: 1rem
  }

  15% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  20% {
    transform: translateY(0);
    height: 1rem
  }

  50% {
    transform: translateY(0);
    height: 1rem
  }

  55% {
    transform: translateY(0);
    height: 1.5rem
  }

  60% {
    transform: translateY(.5rem);
    height: 1rem
  }

  65% {
    transform: translateY(0);
    height: 1.5rem
  }

  70% {
    transform: translateY(0);
    height: 1rem
  }

  to {
    transform: translateY(0);
    height: 1rem
  }
}

@-o-keyframes scroll-down-icon-circle {
  0% {
    transform: translateY(0);
    height: 1rem
  }

  5% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  10% {
    transform: translateY(-.5rem);
    height: 1rem
  }

  15% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  20% {
    transform: translateY(0);
    height: 1rem
  }

  50% {
    transform: translateY(0);
    height: 1rem
  }

  55% {
    transform: translateY(0);
    height: 1.5rem
  }

  60% {
    transform: translateY(.5rem);
    height: 1rem
  }

  65% {
    transform: translateY(0);
    height: 1.5rem
  }

  70% {
    transform: translateY(0);
    height: 1rem
  }

  to {
    transform: translateY(0);
    height: 1rem
  }
}

@keyframes scroll-down-icon-circle {
  0% {
    transform: translateY(0);
    height: 1rem
  }

  5% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  10% {
    transform: translateY(-.5rem);
    height: 1rem
  }

  15% {
    transform: translateY(-.5rem);
    height: 1.5rem
  }

  20% {
    transform: translateY(0);
    height: 1rem
  }

  50% {
    transform: translateY(0);
    height: 1rem
  }

  55% {
    transform: translateY(0);
    height: 1.5rem
  }

  60% {
    transform: translateY(.5rem);
    height: 1rem
  }

  65% {
    transform: translateY(0);
    height: 1.5rem
  }

  70% {
    transform: translateY(0);
    height: 1rem
  }

  to {
    transform: translateY(0);
    height: 1rem
  }
}




.cursor .big .background {
  position: absolute;
  top: -35px;
  left: -35px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #a48bff;
  transition: background-color 0s .3s
}

html.is-section-crew .cursor .big .background {
  background-color: #a48bff
}

html.is-section-privatekey .cursor .big .background {
  background-color: #85a5ff
}

html.is-section-onscout .cursor .big .background {
  background-color: #fff
}

html.is-section-isonline .cursor .big .background {
  background-color: #f2acf2
}

html.is-section-ideasby .cursor .big .background {
  background-color: #f9d77f
}

html.is-section-takemeto .cursor .big .background {
  background-color: #fff
}

.cursor .big .plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform .5s;
  will-change: transform
}

html.is-vertical .cursor .big .plus-icon {
  transform: scale(0)
}

html.is-vertical.is-focused .cursor .big .plus-icon {
  transform: scale(1)
}

.cursor .big .plus-icon .plus-icon-inner {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;
  transition: transform .5s;
  will-change: transform
}

html.is-focused .cursor .big .plus-icon .plus-icon-inner {
  transform: rotate(225deg)
}

.cursor .big .plus-icon .plus-icon-inner:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
  background: #000
}

.cursor .big .plus-icon .plus-icon-inner:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  width: 100%;
  height: 4px;
  background: #000
}

.cursor .big .tap-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: transform .5s;
  will-change: transform
}

html.is-vertical .cursor .big .tap-icon {
  transform: scale(1)
}

html.is-vertical.is-focused .cursor .big .tap-icon {
  transform: scale(0)
}

.cursor .big .tap-icon .circle {
  position: absolute;
  top: -13px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 4px solid #000;
  animation: tap-icon-circle 2s ease-in-out infinite
}

/* @-moz-keyframes tap-icon-circle {
  0% {
      transform: scale(1)
  }

  2% {
      transform: scale(.6)
  }

  20% {
      transform: scale(1.2)
  }

  30% {
      transform: scale(1)
  }

  to {
      transform: scale(1)
  }
}

@-webkit-keyframes tap-icon-circle {
  0% {
      transform: scale(1)
  }

  2% {
      transform: scale(.6)
  }

  20% {
      transform: scale(1.2)
  }

  30% {
      transform: scale(1)
  }

  to {
      transform: scale(1)
  }
}

@-o-keyframes tap-icon-circle {
  0% {
      transform: scale(1)
  }

  2% {
      transform: scale(.6)
  }

  20% {
      transform: scale(1.2)
  }

  30% {
      transform: scale(1)
  }

  to {
      transform: scale(1)
  }
}

@keyframes tap-icon-circle {
  0% {
      transform: scale(1)
  }

  2% {
      transform: scale(.6)
  }

  20% {
      transform: scale(1.2)
  }

  30% {
      transform: scale(1)
  }

  to {
      transform: scale(1)
  }
} */

.cursor .big .tap-icon .sparkle {
  position: absolute;
  top: 0;
  left: 0
}

.cursor .big .tap-icon .sparkle:nth-child(1) {
  transform: rotate(-45deg)
}

.cursor .big .tap-icon .sparkle:nth-child(2) {
  transform: rotate(45deg)
}

.cursor .big .tap-icon .sparkle:nth-child(3) {
  transform: rotate(110deg)
}

.cursor .big .tap-icon .sparkle:nth-child(4) {
  transform: rotate(135deg)
}

.cursor .big .tap-icon .sparkle:nth-child(5) {
  transform: rotate(205deg)
}

.cursor .big .tap-icon .sparkle .sparkle-inner {
  position: absolute;
  top: 0;
  left: 0;
  animation: tap-icon-sparkle-inner 2s ease-out infinite
}

.cursor .big .tap-icon .sparkle .sparkle-inner .sparkle-inner-inner {
  top: -2px;
  left: -2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #000;
  animation: tap-icon-sparkle-inner-inner 2s ease-in-out infinite
}

/* @-moz-keyframes tap-icon-sparkle-inner-inner {
  0% {
      transform: scale(1)
  }

  30% {
      transform: scale(0)
  }

  to {
      transform: scale(0)
  }
}

@-webkit-keyframes tap-icon-sparkle-inner-inner {
  0% {
      transform: scale(1)
  }

  30% {
      transform: scale(0)
  }

  to {
      transform: scale(0)
  }
}

@-o-keyframes tap-icon-sparkle-inner-inner {
  0% {
      transform: scale(1)
  }

  30% {
      transform: scale(0)
  }

  to {
      transform: scale(0)
  }
}

@keyframes tap-icon-sparkle-inner-inner {
  0% {
      transform: scale(1)
  }

  30% {
      transform: scale(0)
  }

  to {
      transform: scale(0)
  }
}

@-moz-keyframes tap-icon-sparkle-inner {
  0% {
      transform: translate(3px)
  }

  30% {
      transform: translate(25px)
  }

  to {
      transform: translate(25px)
  }
}

@-webkit-keyframes tap-icon-sparkle-inner {
  0% {
      transform: translate(3px)
  }

  30% {
      transform: translate(25px)
  }

  to {
      transform: translate(25px)
  }
}

@-o-keyframes tap-icon-sparkle-inner {
  0% {
      transform: translate(3px)
  }

  30% {
      transform: translate(25px)
  }

  to {
      transform: translate(25px)
  }
}

@keyframes tap-icon-sparkle-inner {
  0% {
      transform: translate(3px)
  }

  30% {
      transform: translate(25px)
  }

  to {
      transform: translate(25px)
  }
} */

.box-area-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none
}

.box-area {
  position: absolute;
  top: calc(50% - 40vh);
  left: calc(50% - 30vh);
  height: 80vh;
  width: 60vh;
  pointer-events: auto
}

.box-area.is-pending {
  display: none
}

html.is-vertical .box-area {
  top: calc(50% - 50vw);
  left: calc(50% - 40vw);
  width: 80vw;
  height: 100vw
}

html.is-focused .box-area {
  top: calc(50% - 40vh);
  left: 0;
  height: 80vh;
  width: 50%
}

html.is-vertical.is-focused .box-area {
  top: 0;
  right: 0;
  left: auto;
  width: 30%;
  height: 16%;
  margin-top: 0;
  margin-left: 0
}

.main-intro {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none
}

html.is-intro-entering .main-intro .text>span>span {
  transform: translateY(0)
}

html.is-intro-entering .main-intro .main-logo-loader {
  opacity: 1;
  transform: translateY(0);
  transition-delay: .9s
}

html.is-intro-leaving .main-intro .text>span>span {
  transform: translateY(-100%);
  transition-timing-function: cubic-bezier(.5, 0, 1, 1)
}

html.is-intro-leaving .main-intro .text>span>span.delay-0 {
  transition-delay: 0s
}

html.is-intro-leaving .main-intro .text>span>span.delay-1 {
  transition-delay: .06s
}

html.is-intro-leaving .main-intro .text>span>span.delay-2 {
  transition-delay: .12s
}

html.is-intro-leaving .main-intro .text>span>span.delay-3 {
  transition-delay: .18s
}

html.is-intro-leaving .main-intro .text>span>span.delay-4 {
  transition-delay: .24s
}

html.is-intro-leaving .main-intro .text>span>span.delay-5 {
  transition-delay: .3s
}

html.is-intro-leaving .main-intro .text>span>span.delay-6 {
  transition-delay: .36s
}

html.is-intro-leaving .main-intro .text>span>span.delay-7 {
  transition-delay: .42s
}

html.is-intro-leaving .main-intro .text>span>span.delay-8 {
  transition-delay: .48s
}

html.is-intro-leaving .main-intro .main-logo-loader {
  opacity: 0;
  transform: translateY(-35px);
  transition-timing-function: cubic-bezier(.5, 0, 1, 1);
  transition-delay: .4s
}

.main-intro .text {
  text-align: center;
  font-family: "Sequel 100 Wide";
  font-size: 70px;
  line-height: 1.25em
}

@media (max-width: 1600px) {
  .main-intro .text {
    font-size: 6vmin
  }
}

.main-intro .text>span {
  position: relative;
  display: inline-block;
  overflow: hidden
}

.main-intro .text>span>span {
  position: relative;
  display: inline-block;
  transform: translateY(100%);
  transition: transform .6s cubic-bezier(0, 0, 0, 1);
  will-change: transform
}

.main-intro .text>span>span.delay-0 {
  transition-delay: 0s
}

.main-intro .text>span>span.delay-1 {
  transition-delay: .09s
}

.main-intro .text>span>span.delay-2 {
  transition-delay: .18s
}

.main-intro .text>span>span.delay-3 {
  transition-delay: .27s
}

.main-intro .text>span>span.delay-4 {
  transition-delay: .36s
}

.main-intro .text>span>span.delay-5 {
  transition-delay: .45s
}

.main-intro .text>span>span.delay-6 {
  transition-delay: .54s
}

.main-intro .text>span>span.delay-7 {
  transition-delay: .63s
}

.main-intro .text>span>span.delay-8 {
  transition-delay: .72s
}

.main-intro .main-logo-loader {
  position: absolute;
  top: 80%;
  left: 50%;
  opacity: 0;
  transform: translateY(35px);
  transition: opacity .6s cubic-bezier(0, 0, 0, 1), transform .6s cubic-bezier(0, 0, 0, 1)
}

.join {
  position: fixed;
  top: 50px;
  right: 50px;
  letter-spacing: 1px;
  font-weight: 700;
  opacity: 0;
  transform: translateY(35px);
  transition: opacity .6s cubic-bezier(0, 0, 0, 1), transform .6s cubic-bezier(0, 0, 0, 1)
}

@media (max-width: 1600px) {
  .join {
    top: 40px;
    right: 40px
  }
}

@media (max-width: 1280px) {
  .join {
    top: 35px;
    right: 35px
  }
}

@media (max-width: 1024px) {
  .join {
    top: 25px;
    right: 25px
  }
}

@media (max-width: 760px) {
  .join {
    top: 20px;
    right: 20px
  }
}

@media (max-width: 520px) {
  .join {
    top: 15px;
    right: 15px
  }
}

@media (max-width: 420px) {
  .join {
    top: 10px;
    right: 10px
  }
}

.join.is-visible {
  opacity: 1;
  transform: translateY(0)
}

html.is-vertical .join.is-leaving {
  opacity: 0;
  transform: translateY(-35px);
  transition: opacity .6s ease-in, transform .6s ease-in;
  pointer-events: none
}

.main-logo-header {
  /* position: fixed; */
  top: 3rem;
  left: 100rem;
  opacity: 0;
  transform: translateY(35px);
  transition: opacity .6s cubic-bezier(0, 0, 0, 1), transform .6s cubic-bezier(0, 0, 0, 1)
}

/* @media (max-width: 1600px) {
  .main-logo-header {
      top:1rem;
      left: 5rem;
  }
} */

/* @media (max-width: 1280px) {
  .main-logo-header {
      top:60px;
      left: 90px
  }
} */

/* @media (max-width: 1024px) {
  .main-logo-header {
      top:50px;
      left: 80px
  }
} */

/* @media (max-width: 760px) {
  .main-logo-header {
      top:40px;
      left: 70px
  }
} */

/* @media (max-width: 520px) {
  .main-logo-header {
      top:35px;
      left: 60px
  }
} */

/* @media (max-width: 420px) {
  .main-logo-header {
      top:30px;
      left: 50px
  }
} */

.main-logo-header.is-visible {
  opacity: 1;
  transform: translateY(0)
}

html.is-vertical .main-logo-header.is-leaving {
  opacity: 0;
  transform: translateY(-35px);
  transition: opacity .6s ease-in, transform .6s ease-in;
  pointer-events: none
}

.main-logo {
  position: absolute;
  top: -2.2rem;
  left: 1rem;
  width: 100px;
  height: 70px;
  perspective: 3000px
}

.main-logo * {
  position: absolute;
  transform-style: preserve-3d;
  backface-visibility: visible;
  -webkit-backface-visibility: visible;
}

.main-logo .texture {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden
}



@-moz-keyframes main-logo-texture {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-99px)
  }
}

@-webkit-keyframes main-logo-texture {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-99px)
  }
}

@-o-keyframes main-logo-texture {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-99px)
  }
}

@keyframes main-logo-texture {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-99px)
  }
}


/* .main-logo .texture img {
  animation: main-logo-texture 3s linear infinite;
  
} */
/* .main-logo .part-1 {
  top: -8px;
  left: -35px;
  width: 45px;
  height: 16px;
  transform: rotateY(-45deg);
  transform-origin: 0 50%
} */

/* .main-logo .part-2 {
  top: 0;
  left: calc(100% - 1px);
  width: 8px;
  height: 160px;
  transform: rotateY(45deg);
  transform-origin: 0 50%
} */


/* .main-logo .part-3 {
  top: 0;
  left: calc(100% - 1px);
  width: 45px;
  height: 16px;
  transform: rotateY(45deg);
  transform-origin: 0 50%
} */

/* .main-logo .part-2 img {
  left: -45px
}

.main-logo .part-3 img {
  left: -50px
} */

.main-logo .pivot {
  position: absolute;
  top: 40%;

  left: 50%;
  transform: rotateX(-13deg)
}

.main-logo .texture img {
  animation: main-logo-texture 5s linear 5s infinite alternate;
}

.main-logo .part-1 {
  top: -8px;
  left: -40px;
  width: 50px;
  height: 50px;
  transform: rotateY(-45deg);
  transform-origin: 0 50%
}


.main-logo .part-3 {
  top: 0;
  left: calc(100% - 1px);
  width: 200px;
  height: 50px;
  transform: rotateY(50deg);
  transform-origin: 0 50%
}

.main-logo .part-2 {
  top: 0;
  left: calc(100% - 1px);
  width: 9px;
  height: 50px;
  transform: rotateY(45deg);
  transform-origin: 0 50%
}


.main-logo .part-2 img {
  left: 5px
}

.main-logo .part-3 img {
  left: -3px
}


.main-logo .part-1.back {
  left: 35px;
  transform: rotateY(135deg)
}

.main-logo .part-1.back img {
  opacity: .2
}

.main-logo .part-2.back {
  left: 100%;
  width: 8px
}

.main-logo .part-3.back {
  left: 100%;
  width: 45px
}











.button-a {
  align-items: center;
  /* background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB); */
  background-image: conic-gradient(#488cfb,
      #29dbbc,
      #ddf505,
      #ff9f0e,
      #e440bb,
      #655adc,
      #488cfb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 18px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all .3s;
}

.button-a-position {
  position: absolute;
  top: 30rem;
  right: 3rem;
}

.button-a:active,
.button-a:hover {
  outline: 0;
}

.button-a span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-a:hover span {
  background: none;
}

.button-a:active {
  transform: scale(0.9);
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

/* button {
  margin: 20px;
} */

/* 6 */
.btn-6 {
  background: rgb(247, 150, 192);
  background: radial-gradient(circle, rgba(247, 150, 192, 1) 0%, rgba(118, 174, 241, 1) 100%);
  line-height: 42px;
  padding: 0;
  border: none;
}

.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
  box-shadow:
    -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, .4),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
}

.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}

.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}

.btn-6:hover {
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}

.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}

.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}

.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  box-shadow:
    -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, .4),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
}

.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}

.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}

.btn-6 span:hover:before {
  width: 100%;
}

.btn-6 span:hover:after {
  width: 100%;
}






/* sextion-box */
.section-box {
  /* padding: 2vw 2vw; */
  padding-top: 5vw;
  /* padding-bottom: 5vw; */
  box-sizing: border-box;
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  position: relative;
  overflow: hidden;
}


.section-box::before {
  padding-top: 4rem;
  content: "";
  position: absolute;
  width: 50vw;
  height: 50vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #ecf7dee5 0%, #ffffff00 100%);
  top: 90%;
  left: 20%;
  transform: translate(-60%, -50%);
  opacity: 1;
  z-index: -3;
}

.section-box::after {
  content: "";
  position: absolute;
  width: 40vw;
  height: 40vw;
  background: radial-gradient(49.81% 49.81% at 53.77% 52.87%, #f6dfd9 0%, #ffffff00 100%);
  transform: translate(40%, -40%);
  top:10%;
  right: -5%;
  opacity: 1;
  z-index: -5;
}

.section-item-box {
  background-image: linear-gradient(37.41deg, #bcddf7 3.98%, #FFFFFF 96.37%);

  border-radius: 3vw;
  text-align: center;
  padding: 3vh 0;
  position: relative;

}

/* .section-item-box:hover {
  border: 2px solid rgb(79, 249, 113);
  transition: all .5s;
} */

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

.section-item-box::before {
  content: "";
  width: 102%;
  height: 102%;
  border-radius:3.2vw;
  background-image: linear-gradient(var(--rotate), #50e1fb51, rgba(255, 223, 159, 0.6), rgba(245, 165, 197, 0.384), rgba(168, 255, 62, 0.288), rgba(168, 255, 62, 0) 43%);
  position: absolute;
  z-index: -1;
  top: -.9%;
  left: -.9%;
  animation: spin 4s linear infinite;
}



@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

.sec-col:nth-child(2) .section-item-box {
  background-image: linear-gradient(37.41deg, #c4f8c3 3.98%, #FFFFFF 96.37%);
 
}

.sec-col:nth-child(3) .section-item-box {
  background-image: linear-gradient(37.41deg, #f8ccc3 3.98%, #FFFFFF 96.37%);
}

.sec-col:nth-child(4) .section-item-box {
  background-image: linear-gradient(37.41deg, #d3a7ff 3.98%, #FFFFFF 96.37%);
}

.sec-col:nth-child(5) .section-item-box {
  background-image: linear-gradient(37.41deg, #5ff4ee 3.98%, #FFFFFF 96.37%);
}

.sec-col:nth-child(6) .section-item-box {
  background-image: linear-gradient(37.41deg, #ffe9a4 3.98%, #FFFFFF 96.37%);
}


.section-item-img {
  width: 100%;
  margin-top: -50%;
  
}

.section-item-tit {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  width: 50%;
  margin: auto;
  margin-top: 16%;
  /* margin-bottom: 8%; */
  text-align: left;
}

.section-item-label {
  font-family: "Ubuntu", sans-serif;
  width: 50%;
  margin: auto;
  font-size: 1rem;
  color: white;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .section-box .sec-col:nth-child(3n - 1) {
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
}



.imgBox{
  position: relative;
}
.imgFloat{
  
}
.imgFCol{
  /* border: 1px solid red; */
  text-align: center;
}
.imgF{
  width: 60%;
  max-width: 400px;
}

.descFloat{
  max-width: 600px;
}

@media screen and (min-width: 576px) {
  .section-box .sec-col:nth-child(even) {
    margin-top: 6vw;
  }
}
.xsWhy{
  display: none;
}
@media screen and (max-width: 700px) {
  .whyCol{
    display: none;
  }
  .xsWhy{
    display: block;
    position: relative;
  }
  .xsWhyImg{
    width: 50%;
  }
  .xsWhyDescBox{
    position: absolute;
    /* border: 1px solid red; */
    width: 60%;
    right: 0;

  }
  .xsWhyDescBox .p12{
    /* font-size: 14px; */
  }
}
@media screen and (min-width: 1190px) {
  
}





.card {
  background: #191c29;
  width: var(--card-width);
  height: var(--card-height);
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: rgb(88 199 250 / 0%);
  cursor: pointer;
  font-family: cursive;
}

.card:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}
.card:hover:before, .card:hover:after {
  animation: none;
  opacity: 0;
}


.card::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate)
    , #5ddcff, #3c67e3 43%, #4e00c2);
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(
    var(--rotate)
    , #5ddcff, #3c67e3 43%, #4e00c2);
    opacity: 1;
  transition: opacity .5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}