
// progress bar appearance
$background-color:rgba(255, 192, 203, 0.298);
$bar-empty:#261F7F;
$bar-color:#033fff;

$glow-width:30px;
$glow-radius:10px;
$glow-color:#5ff4efa9;

.circle {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background-color: rgba(3,63,255,0.8);
  // border: solid 1px black;
  border-radius: 50%;
  position: absolute;
  top:-3px;
  left: calc(110%);
}


.circle1 {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-color: rgba(87,199,224,0.7);
    // border: solid 1px black;
    border-radius: 50%;
    position: absolute;
    top:-5px;
    left: calc(110%);
    z-index: -1;
  }
  
.progress-bar {
  height:3px;
  width:100%;
  position:relative;
  z-index:10;

  background-color:$bar-empty;
  
  .progress {
    position:relative;
    height:100%;
    background-color:$bar-color;
    transition:width 0.5s ease-out;
    border-radius: 0px 2px 2px 0px;
    overflow: visible;
    float:right !important;
  }
  .glow {
    width:$glow-width;
    max-width:100%;
    height:100%;
    float:right !important;

  }
  .glow::before,
  .glow::after {
    content:'';
    display:block;
    position:relative;
    border-radius:0px px 2px 0px;
    
  }
  .glow::before {
    background:transparent;
    height:100%;
    box-shadow:0px 0px $glow-radius $glow-color, 1px 1px $glow-radius $glow-color;
    z-index:-5;
  }
  .glow::after {
    // background:linear-gradient(to right, $background-color 0%, transparent 80%);
    // background:transparent;
    height:calc(100% + #{$glow-radius} + #{$glow-radius});
    width:calc(100% + #{$glow-radius});
    top:(-$glow-radius);
    left:(-$glow-radius);
    z-index:-3;
  }
}

// messages 
.label {
  font-family:Poppins;
  color:white;
  text-shadow:0px 0px 2px $bar-color;
  font-size:24px;
}

// animations
.fill-1 {
  animation:fill-1 0.5s ease-out 0s;
  animation-fill-mode:forwards;    
  width:0%;
}
.fill-2 {
  animation:fill-2 0.5s ease-out 0.5s;
  animation-fill-mode:forwards;   
  width:0%;
}
.fill-3 {
  animation:fill-3 0.5s ease-out 1s;
  animation-fill-mode:forwards;   
  width:0%;
}
@keyframes fill-1 {
  0% { width:0%; }
  100% { width:20%; }
}
@keyframes fill-2 {
  0% { width:0%; }
  100% { width:40%; }
}
@keyframes fill-3 {
  0% { width:0%; }
  100% { width:90%; }
}
.label.one {
  animation:label 0.5s ease-out 0s;
  animation-fill-mode:forwards;   
  opacity:0;
}
.label.two {
  animation:label 0.5s ease-out 0.5s;
  animation-fill-mode:forwards;   
  opacity:0;
}
.label.three {
  animation:label 0.5s ease-out 1.0s;
  animation-fill-mode:forwards;
  opacity:0;
}
@keyframes label {
  0% { opacity:0; transform:translateY(10px); }
  100% { opacity:0.9; transform:translateY(0); }
}


$position-values: (
  
  30: 30%,
  
);


